<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.themeSetting.title') }}
          -
          {{ $t('settings.project.projectType.themeSetting.direct_right.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <component
      v-for="(themeConfigItem, index) in themeFieldConfig"
      :key="index"
      :is="themeConfigItem.fieldType"
      :handle="themeConfigItem.handle"
      :label="themeConfigItem.title"
      :desc="themeConfigItem.desc"
      :value-from-db="themeValues[themeConfigItem.handle]"
      @update-field="updateSettingValue($event, themeConfigItem.handle)"
    />
  </div>
</template>

<script>
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import InputRow from '@/components/helper/InputRow.vue';

export default {
  name: 'ThemeSettingsDirectRight',
  components: {
    CheckboxRow,
    ImageUploadRow,
    InputRow,
  },
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      themeFieldConfig: [
        {
          handle: 'logoimage',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.logoimage.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.logoimage.desc'),
          fieldType: 'ImageUploadRow',
        },
        {
          handle: 'show_logo',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.show_logo.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'menuTags',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.menuTags.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.menuTags.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'shadow',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.shadow.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_background',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.navigation_background.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.navigation_background.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'pagination',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.pagination.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.pagination.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'search',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.search.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.search.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'sharing',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.sharing.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'sharing_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.sharing_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.sharing_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'cookies',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.cookies.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'cookies_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.cookies_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.cookies_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'print',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.print.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'print_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.print_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.print_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'overview',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.overview.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'overview_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.overview_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.overview_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.imprint.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.imprint_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.imprint_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.dataprivacy.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_right.dataprivacy_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_right.dataprivacy_tooltip.desc'),
          fieldType: 'InputRow',
        },
      ],
      themeValues: {
        logoimage: {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        },
        show_logo: true,
        menuTags: false,
        shadow: false,
        navigation_background: false,
        pagination: true,
        search: false,
        sharing: false,
        sharing_tooltip: null,
        cookies: true,
        cookies_tooltip: null,
        print: null,
        print_tooltip: null,
        overview: null,
        overview_tooltip: null,
        imprint: null,
        imprint_tooltip: null,
        dataprivacy: null,
        dataprivacy_tooltip: null,
      },
    };
  },
  created() {
    // add the values to the data
    if (this.settingValues) {
      Object.entries(this.settingValues).forEach((itemValue) => {
        const key = itemValue[0];
        const value = itemValue[1];
        if (this.themeValues.hasOwnProperty(key) && value != null) {
          this.themeValues[key] = value;
        }
      });
      if (this.themeValues.logoimage.url) {
        if (!this.$checkForCloudImage(this.themeValues.logoimage.url)) {
          this.themeValues.logoimage.url = this.$makeCloudImage(
            this.themeValues.logoimage.url,
            { width: 450, height: 300 },
            this.themeValues.logoimage,
          );
        }
      }
    }
    this.$emit('update-theme-settings', this.themeValues);
  },
  methods: {
    updateSettingValue(value, handle) {
      if (this.themeValues.hasOwnProperty(handle)) {
        this.themeValues[handle] = value;
        this.$emit('update-theme-settings', this.themeValues);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
